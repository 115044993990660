<template>
    <div class="page">
        <div id="top" class="search_wap">
            <div class="a-inline-block">
                <a-input-search v-model="osn" class="sInput" placeholder="请输入合同编号" @search="onSearch"/>
            </div>
        </div>
        
        <div v-if="this.pagination.total" style="text-align: right;margin-right: 20px;">当前数据条数{{this.pagination.total}}条</div>
        <div v-infinite-scroll="getOrder" infinite-scroll-disabled="dataLoadStop" infinite-scroll-distance="50">
            <div class="lists" v-for="(item,index) in data" :key="index">
                <div class="list_row">
                    <div><label for="">合同编号：</label>{{item.osn}}</div>
                    <div class="flex">
                        <div><label for="">客户名称：</label>{{item.name}}</div>
                        <div><label for="">状态：</label>{{item.status}}</div>
                    </div>
                    <div class="flex">
                        <div><label for="">合同金额：</label>{{item.amount}}</div>
                        <div><label for="">跟进人：</label>{{item.staff_uid}}</div>
                    </div>
                    <div class="flex">
                        <div><label for="">应收金额：</label>{{item.invoice_amount}}</div>
                        <div><label for="">实收金额：</label>{{item.payment_amount}}</div>
                        
                    </div>
                    <div class="flex">
                        <div><label for="">退款金额：</label>{{item.refund_amount}}</div>
                        <div><label for="">补签金额：</label>{{item.replenish_amount}}</div>
                    </div>
                    <div class="flex">
                        <div><label for="">签订时间：</label>{{item.sign_time}}</div>
                        <div><label for="">到期时间：</label>{{item.end_time}}</div>
                    </div>
                    <div><label for="">创建时间：</label>{{item.created}}</div>
                </div>
                <div class="btn_con">
                    <a-button type="primary" size="small" ghost @click="showDetail(item.id)">查看</a-button>
                    <template  v-if="item.status != '已完成' && item.status != '关闭'">
                        <a-button type="primary" size="small" ghost @click="editOrder(item.id)">编辑</a-button>
                        <a-button type="primary" size="small" ghost @click="finish(item.id)">完成</a-button>
                        <a-popconfirm title="确定要关闭吗?" @confirm="() => off(item.id)">
                            <a-button type="danger" size="small" ghost>关闭</a-button>
                        </a-popconfirm>
                    </template>
                    <a-popconfirm title="确定要删除吗?" @confirm="() => onDelete(item.id)">
                        <a-button type="danger" size="small" ghost>删除</a-button>
                    </a-popconfirm>
                </div>
            </div>
            <!-- <div class="list-load-end" style="text-align:center;padding-top:25px;padding-bottom:50px">
                <span>{{dataLoadNomore ? "--没有更多数据了--" : "加载中..."}}</span>
            </div> -->
            
            <a-pagination v-if="pagination.total" style="text-align: center;" @change="onChange" v-model="pagination.current" :total="pagination.total" />
            <div v-if="pagination.total==0" class="list-load-end" style="text-align:center;padding-top:25px;padding-bottom:50px">
                <span>--暂无数据--</span>
            </div>
        </div>
    </div>
</template>
<script>
// import $ from "jquery";
import infiniteScroll from "vue-infinite-scroll";
import '../../../assets/css/wapList.css';
import {requestXml,getDateTime} from '../../../assets/js/request';
export default {
    directives: {infiniteScroll},
    data() {
        return {
            // 搜索的内容
            osn: "",
            orderStatus: ["关闭","新建","进行中","已完成"],
            data:[],

             // 分页
            dataLoadStop: false,  //列表数据加载停止状态
            dataLoadNomore: false,    //列表数据加载是否完成
            pagination: {
                current: 1,
                total: 100,
                pageSize: 10, // 默认每页显示数量
                showSizeChanger: true, // 显示可改变每页数量
                pageSizeOptions: ['10', '20', '50', '100'], // 每页数量选项
                showQuickJumper: true,
                // showTotal: total => `Total ${total} items`, // 显示总数
                onChange: (current) => this.pagesChange(current), //点击当前页码
                onShowSizeChange: (current, pageSize) => {
                    //设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                    console.log(pageSize);
                    this.showSizeChange(current, pageSize);
                },
            },
           
        };
    },
    beforeRouteEnter(to, from, next) {
      next(vm=>{
        vm.fromPath = from.path
      })
    },
    beforeDestroy(){
        sessionStorage.setItem('curpage',JSON.stringify({"osn": this.osn,'page':this.pagination}))
    },
    mounted: function(){
        if(this.fromPath == "/scrm_wap/order/orderAdd" || this.fromPath == '/scrm_wap/order/orderDetail') {
            var data=JSON.parse(sessionStorage.getItem('curpage'))
            sessionStorage.removeItem('curpage')
            this.pagination=data.page
            this.osn=data.osn
        }
        this.getOrder();
    },
    methods:{
        onChange(){
            window.location.href='#top'
            this.getOrder();
        },
        // 获取合同列表
        getOrder() {
            this.dataLoadStop = true;
            requestXml("/scrm/Order/getMyList","POST",(res) => {
                for(let i=0;i<res.list.length;i++){
                    res.list[i].sign_time = getDateTime(res.list[i].sign_time);
                    res.list[i].created = getDateTime(res.list[i].created);
                    
                    res.list[i].status = this.orderStatus[Number(res.list[i].status)];
                }
                this.data = res.list;
                // this.data = this.data.concat(res.list);
                // if(res.page.maxpage>this.pagination.current){
                //     this.pagination.current += 1
                //     this.dataLoadStop = false
                // }else{
                //     this.dataLoadStop = true
                //     this.dataLoadNomore = true
                // }
                this.pagination.total = Number(res.page.total);
                this.pagination.pageSize = Number(res.page.pagesize);
            },{ "osn": this.osn,"page":{"curpage":this.pagination.current,"pagesize":this.pagination.pageSize}})
        },
        onSearch(){
            this.data=[];
            this.dataLoadNomore=false;
            this.pagination.current=1;
            this.getOrder();
        },
        // 查看
        showDetail(id){
            this.$router.push({
                path: "/scrm_wap/order/orderDetail",
                query: {
                    id: id,
                }
            });
        },
        // 编辑
        editOrder(id){
            this.$router.push({
                path: "/scrm_wap/order/orderAdd",
                query: {
                    id: id,
                }
            });
        },
        // 完成
        finish(id){
            requestXml("/scrm/Order/setDone","POST",(res) => {
                if(res == "success"){
                    this.data = [];
                    this.getOrder();
                }
            },{"id": id})
        },
        // 关闭
        off(id){
            requestXml("/scrm/Order/setClose","POST",(res) => {
                if(res == "success"){
                    this.data = [];
                    this.getOrder();
                }
            },{"id": id})
        },
        // 删除
        onDelete(id){
            requestXml("/scrm/Order/del","POST",(res) => {
                if(res == "success"){
                    this.data = [];
                    this.getOrder();
                }
            },{"id": id})
        }
        
    }
};
</script>
<style>
</style>
